import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import SnackbarAlert from 'components/SnackbarAlert';
import PageHeader from 'components/PageHeader';
import PageActionsHeader from 'components/PageActionsHeader';
import GAContainer from 'components/Layout/GAContainer';
import { CampaignFinanceLanding } from '@/features/campaign-finance';

const CampaignFinanceLandingContainer = ({ messages }) => {
  // TODO: When available, remove the snackbar and ref as it's already available in the ApplicationContextProvider. Then update bulkimport accordingly.
  const _snackbar = useRef();

  return (
    <>
      <SnackbarAlert ref={_snackbar} />

      <PageHeader
        heading={messages.header.heading}
        subheading={messages.header.subheading}
      >
        {/* // TODO: not MVP */}
        {false && (
          <div>
            <Typography
              component="p"
              variant="body2"
              color="textSecondary"
            >
              {messages.header.lastImported}
            </Typography>
            <Typography
              variant="body1"
              component="p"
            >
              @January 1, 1970 12:00AM@
            </Typography>
          </div>
        )}
        <></>
      </PageHeader>

      <PageActionsHeader>
        <Button
          color="secondary"
          variant="contained"
          href="/campaign-finance/import"
        >
          {messages.links.import}
        </Button>
      </PageActionsHeader>

      <GAContainer>
        <CampaignFinanceLanding
          messages={messages}
        />
      </GAContainer>
    </>
  );
};

CampaignFinanceLandingContainer.propTypes = {
  messages: PropTypes.object,
};

export { CampaignFinanceLandingContainer };
