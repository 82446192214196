import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import LanguagesAppliedToGuide from 'components/LanguagesAppliedToGuide';
import Form from 'helpers/Form';

const styles = {
  addFormContainer: {
    display: 'flex',
    margin: '1rem',
  },
  addFormAbbrField: {
    marginRight: '2rem',
    width: '20rem',
  },
  addFormActions: {
    backgroundColor: '#eef4f8',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '.8rem 2rem',
  },
  cardContainerNoOutline: {
    border: 'none',
    boxShadow: 'none',
  },
};

class RaceQuestionSetForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: new Form({
        name: props.name,
      }, {
        validationMessage: props.validationErrorMessage,
      }),
      submitting: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const { name } = event.target;
    const { value } = event.target;
    const { form } = this.state;

    form[name] = value;
    this.forceUpdate();
  }

  handleSubmit() {
    const {
      onError, onSuccess, submitMethod, submitUrl,
    } = this.props;
    const { form } = this.state;

    this.setState({
      submitting: true,
    });

    form.submit(submitMethod, submitUrl)
      .then(response => {
        this.setState({
          submitting: false,
        });

        onSuccess(submitMethod, response);
      })
      .catch(error => {
        this.setState({
          submitting: false,
        });

        onError(submitMethod, error);
      });
  }

  render() {
    const {
      children,
      classes,
      buttonText,
      languages,
      outlined,
      requiredText,
    } = this.props;
    const { form, submitting } = this.state;

    let cardContainerClass = '';

    if (!outlined) {
      cardContainerClass = classes.cardContainerNoOutline;
    }

    return (
      <Card variant="outlined" className={cardContainerClass}>
        <CardContent className={classes.addFormContainer}>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  id="name"
                  disabled={submitting}
                  error={form.errors.has('name')}
                  helperText={form.errors.get('name')}
                  label="Race Question Set Name"
                  name="name"
                  required
                  variant="filled"
                  onChange={this.handleInputChange}
                  value={form.name}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <LanguagesAppliedToGuide languages={languages} />
            </Grid>
          </Grid>
        </CardContent>

        <CardActions className={classes.addFormActions}>
          <Typography component="span" variant="body2">
            {requiredText}
          </Typography>

          <div>
            {children}

            <Button
              variant="contained"
              color="secondary"
              onClick={this.handleSubmit}
              size="large"
              disabled={submitting}
            >
              {buttonText}
            </Button>
          </div>

        </CardActions>
      </Card>
    );
  }
}

RaceQuestionSetForm.propTypes = {
  buttonText: PropTypes.string,
  classes: PropTypes.object,
  children: PropTypes.node,
  // id: PropTypes.string,
  languages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  onError: PropTypes.func,
  onSuccess: PropTypes.func,
  name: PropTypes.string,
  requiredText: PropTypes.string,
  outlined: PropTypes.bool,
  submitMethod: PropTypes.oneOf(['POST', 'PATCH']),
  submitUrl: PropTypes.string.isRequired,
  validationErrorMessage: PropTypes.string,
};

RaceQuestionSetForm.defaultProps = {
  buttonText: 'Add Question Set',
  // id: '',
  languages: [],
  name: '',
  onError: () => {},
  onSuccess: () => {},
  requiredText: '*Required fields needed to add a new question set',
  outlined: false,
  submitMethod: 'POST',
  validationErrorMessage: 'There was an issue adding your question set. Correct any errors and try again.',
};

export default withStyles(styles)(RaceQuestionSetForm);
