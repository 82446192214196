import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/core/styles';
import {
  Button,
  Box,
  Typography,
} from '@material-ui/core';
// import KPICard from 'components/KPICard';
import ActionsDropdown from 'components/ActionsDropdown';
import ConfirmationDialog from 'components/ConfirmationDialog';
import PageActionsHeader from 'components/PageActionsHeader';
import SnackbarAlert from 'components/SnackbarAlert';
import PageHeader from 'components/PageHeader';
import AddCandidateModal from 'components/Candidates/Partials/AddCandidateModal';
import { CandidateTable } from 'components/Candidates/Partials/CandidateTable';
import CandidateStatus from 'components/Candidates/Partials/CandidateStatus';
import StatusText, { COLOR_OPTIONS } from 'components/StatusText';
import fetchUtil from 'helpers/Fetch';
import useSavedTableSettings from 'hooks/useSavedTableSettings';
import theme from 'scripts/theme';
import useCampaignFinanceStore from 'components/features/campaign-finance/stores/useCampaignFinanceStore';

// MOCK DATA TO BE REPLACED BY API OR SOME OTHER METHOD
// const mockKpiArray = {
//   kpis: [
//     { title: 'In Progress', value: 1, total: 5 },
//     {
//       title: 'Invited',
//       value: 6,
//       total: 10,
//       helpTitle: 'Lorem Ipsum',
//       helpText: 'Lorem Ipsum',
//     },
//     {
//       title: 'Responded',
//       value: 2,
//       total: 10,
//       helpTitle: 'Lorem Ipsum',
//       helpText: 'Lorem Ipsum',
//     },
//     {
//       title: 'Have Photos',
//       value: 3,
//       total: 10,
//       helpText: 'Lorem Ipsum',
//     },
//   ],
//   title: 'Candidate Progress',
// };

const CandidateDashboardContainer = ({
  candidates,
  guideId,
  messages,
}) => {
  const [localCandidates, setLocalCandidates] = useState([]);
  const [candidateSubmitting, setCandidateSubmitting] = useState(false);
  const [showCreateCandidateModal, setShowCreateCandidateModal] = useState(false);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [parties, setParties] = useState([]);
  const [partiesLoaded, setPartiesLoaded] = useState(false);
  const [races, setRaces] = useState([]);
  const [racesLoaded, setRacesLoaded] = useState(false);
  const [showArchiveConfirmationModal, setShowArchiveConfirmationModal] = useState(false);
  const [archiveSelected, setArchiveSelected] = useState([]);
  const [archiveSubmitting, setArchiveSubmitting] = useState(false);
  const [addCandidateErrors, setAddCandidateErrors] = useState({});
  const { enableCampaignFinance } = useCampaignFinanceStore();

  const [filterModel, setFilterModel, sortModel, setSortModel] = useSavedTableSettings({ key: 'candidates' });

  const _snackbar = useRef();

  useEffect(() => {
    fetchParties();
    fetchRaces();
  }, []);

  useEffect(() => {
    setLocalCandidates(candidates);
  }, [candidates]);

  const fetchParties = () => {
    setPartiesLoaded(false);

    fetchUtil(`/api/v1/guide/${guideId}/parties`, 'GET', {})
      .then(resp => {
        setParties(resp);
        setPartiesLoaded(true);
      })
      .catch((error) => console.error(error));
  };

  const fetchRaces = () => {
    setRacesLoaded(false);

    fetchUtil(`/api/v1/guide/${guideId}/race`, 'GET', {})
      .then(resp => {
        setRaces(resp);
        setRacesLoaded(true);
      })
      .catch((error) => console.error(error));
  };

  const handleEdit = (id, raceId) => window.location.replace(`/races/${raceId}/candidates/${id}`);
  // const handleDelete = id => console.log('delete', id);
  // const handleExport = id => console.log('export', id);
  // const handleChangeStatus = id => console.log('change status', id);

  const setRowLoadingState = (candidateIds, loading) => {
    const candidateListCopy = localCandidates.slice().map(c => {
      if (candidateIds.includes(c.id)) {
        c.loading = loading;
        return c;
      }

      c.loading = false;
      return c;
    });

    setLocalCandidates(candidateListCopy);
  };

  const handleShowArchiveConfirmation = (selected) => {
    setArchiveSelected(selected);
    setShowArchiveConfirmationModal(true);
  };

  const handleHideArchiveConfirmation = () => {
    setArchiveSelected([]);
    setShowArchiveConfirmationModal(false);
  };

  const handleArchive = () => {
    setRowLoadingState(archiveSelected, true);
    setArchiveSubmitting(true);

    fetchUtil(`/api/v1/guide/${guideId}/candidate/archive`, 'PUT', { candidateIds: archiveSelected })
      .then(() => {
        const newCandidates = localCandidates.slice().filter(c => !archiveSelected.includes(c.id));
        const message = archiveSelected.length > 1 ? 'Candidates were successfully archived!' : 'Candidate was successfully archived!';
        setLocalCandidates(newCandidates);
        setArchiveSubmitting(false);
        setShowArchiveConfirmationModal(false);
        showSuccess(message);
      })
      .catch(error => {
        console.error(error);

        let message = `There was an issue archiving your ${archiveSelected.length > 1 ? 'candidates' : 'candidate'}. Please try again and contact your guide admin if this issue continues.`;

        if (error.status === 403) {
          message = 'Only guide admins can archive candidates. Please contact your guide admin.';
        }

        setRowLoadingState(archiveSelected, false);
        setArchiveSubmitting(false);
        setShowArchiveConfirmationModal(false);
        showError(message);
      });
  };

  const tableBulkActions = [
    // {
    //   name: 'Delete',
    //   color: 'danger',
    //   onClick: () => {},
    // },
    // {
    //   name: 'Export',
    //   color: 'primary',
    //   onClick: () => {},
    // },
    {
      name: 'Archive',
      color: 'primary',
      onClick: () => handleShowArchiveConfirmation(selectedCandidates),
    },
    // {
    //   name: 'Change Status',
    //   color: 'primary',
    //   onClick: () => {},
    // },
  ];

  const truncate = (str, length) => {
    if (str) {
      return str.length > length ? `${str.slice(0, length)}...` : str;
    }
    return '';
  };

  const tableColumns = useMemo(() => {
    const mainColumns = [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1.4,
        renderCell: (params) => {
          const truncated = truncate(params.row.name, 90);
          return (
            <Box>
              <a href={`/races/${params.row.race.id}/candidates/${params.id}`}>
                {truncated != '' ? truncated : 'NO NAME'}
              </a>
            </Box>
          );
        },
      },
      {
        field: 'parties',
        headerName: 'Party',
        flex: 0.65,
        renderCell: (params) => {
          let text = '';
          if (params.row && params.row.parties && params.row.parties.length > 0) {
            text = params.row.parties
              .map(p => p.abbr ?? p.name)
              .join(', ');
          }
          return text;
        },
        valueGetter: (params) => params.row.parties.map(p => p.name).join(', '),
      },
      {
        field: 'race',
        headerName: 'Race/Initiative',
        flex: 1,
        valueGetter: (params) => truncate(params.row.race.name, 45),
      },
      {
        field: 'category',
        headerName: 'Category',
        flex: 1,
        valueGetter: (params) => {
          // map to category names, or the string 'Uncategorized' if no categories are found
          if (params.row.categories && params.row.categories.length > 0) {
            const categories = (params.row.categories).map((category) => category.title ?? '');
            const truncated = truncate(categories.join(', '), 70);
            return truncated;
          }
          return 'Uncategorized';
        },
      },
      {
        field: 'visible',
        headerName: 'Status',
        flex: 0.6,
        renderCell: (params) => <CandidateStatus status={params.row.state} />,
        valueGetter: (params) => params.row.state.name,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        resizable: false,
        sortable: false,
        width: 120,
        renderCell: (params) => {
          const options = [
            { label: 'Edit', onClick: () => handleEdit(params.id, params.row.race.id) },
            // { label: 'Delete', onClick: () => handleDelete(params.id) },
            // { label: 'Export', onClick: () => handleExport(params.id) },
            { label: 'Archive', onClick: () => handleShowArchiveConfirmation([params.id]) },
            // { label: 'Change status', onClick: () => handleChangeStatus(params.id) },
          ];

          return <ActionsDropdown disabled={params.row.loading == true} options={options} />;
        },
      },
    ];

    if (enableCampaignFinance) {
      const financeColumn = {
        field: 'campaignFinance',
        headerName: 'Campaign Finance',
        flex: 1,
        renderCell: (params) => (
          <StatusText
            color={params.row.campaignFinance ? COLOR_OPTIONS.GREEN : COLOR_OPTIONS.GREY}
            text={params.row.campaignFinance ? messages.add.financeMatch : messages.add.financeNoMatch}
          />
        ),
        valueGetter: (params) => params.row.campaignFinance,
      };

      mainColumns.splice(4, 0, financeColumn);
    }

    return mainColumns;
  }, [enableCampaignFinance]);

  const getRaceById = raceId => races.filter((r) => r.id === raceId);

  // if raceClass = Race (R), then return Candidate (C)
  // otherwise it's a Referendum (F), so return Referendum (R)
  const getRaceType = clazz => (clazz === 'R' ? 'C' : 'R');

  const showSuccess = message => {
    _snackbar.current.hide();
    _snackbar.current.show('success', message);
  };

  const showError = message => {
    _snackbar.current.hide();
    _snackbar.current.show('error', message);
  };

  const handleAddCandidate = (candidate) => {
    if (!candidate || !Object.prototype.hasOwnProperty.call(candidate, 'race') || candidate.race == undefined || !Object.prototype.hasOwnProperty.call(candidate.race, 'id')) {
      showError('You must select a race or measure to add a candidate. Try again, and if the error continues, contact a guide admin.');
      setAddCandidateErrors({ race: ['You must select a race'] });
      return;
    }

    const selectedRace = getRaceById(candidate.race.id);

    if (selectedRace.length <= 0) {
      showError('Selected race not found. Please try again, and if the error persists, contact your guide admin.');
      setCandidateSubmitting(false);
      return;
    }

    const candidateClass = getRaceType(selectedRace[0].clazz.id);
    let inFavor = null;
    if (candidateClass === 'R') {
      inFavor = candidate.inFavor || false;
    }

    const body = {
      name: candidate.name,
      lastName: candidate.lastName,
      candidateState: 1,
      candidateClass,
      raceId: candidate.race.id,
      partiesIds: candidate.parties.map((x) => x.id),
      contactName: candidate.contactUser.name,
      contactEmail: candidate.contactUser.email,
      inFavor,
    };

    setCandidateSubmitting(true);

    fetchUtil(`/api/v1/guide/${guideId}/race/${body.raceId}/candidate`, 'POST', body)
      .then((res) => {
        const updatedCandidates = localCandidates.slice();
        updatedCandidates.push(res);
        setLocalCandidates(updatedCandidates);
        setCandidateSubmitting(false);

        setAddCandidateErrors({});

        showSuccess(messages.add.success);
        setShowCreateCandidateModal(false);
      })
      .catch((error) => {
        console.error(error);
        showError(messages.add.error);
        setCandidateSubmitting(false);
        setAddCandidateErrors(error);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <SnackbarAlert ref={_snackbar} />

      <ConfirmationDialog
        heading="Archive Candidates?"
        onCancel={() => handleHideArchiveConfirmation()}
        onConfirm={() => handleArchive()}
        open={showArchiveConfirmationModal}
        submitting={archiveSubmitting}
      >
        <Typography component="p" variant="body1">
          Are you sure you want to <strong>archive {archiveSelected.length} candidates</strong>? They will <strong>only be accessible via the archived candidates dashboard</strong>.
        </Typography>
      </ConfirmationDialog>

      {showCreateCandidateModal && (
        <AddCandidateModal
          errors={addCandidateErrors}
          guideId={guideId}
          messages={messages.add}
          open={showCreateCandidateModal}
          onCancel={() => setShowCreateCandidateModal(false)}
          onSubmit={handleAddCandidate}
          parties={parties}
          races={races}
          racesLoaded={racesLoaded}
          submitting={candidateSubmitting}
        />
      )}

      <PageHeader
        heading="Candidates"
        subheading="View the candidates that have been set up on your guide. Review our training materials for help to get started."
      />

      <PageActionsHeader>
        <Button
          color="primary"
          variant="text"
          href="/candidates/archived"
        >
          View Archived Candidates
        </Button>

        <Button
          color="secondary"
          variant="contained"
          onClick={() => setShowCreateCandidateModal(true)}
          disabled={!racesLoaded || !partiesLoaded || candidateSubmitting}
        >
          Add Candidate
        </Button>
      </PageActionsHeader>

      <div className="ga-container">
        <div className="mdc-layout-grid">
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
              {/* <div>
                <Typography component="h2" variant="h2">At a Glance</Typography>
                <KPICard {...mockKpiArray} />
              </div> */}

              <CandidateTable
                bulkActions={tableBulkActions}
                candidates={localCandidates}
                columns={tableColumns}
                emptyStateText="There are currently no candidates for this guide."
                filterModel={filterModel}
                heading="Candidates"
                onFilterModelChange={(model) => setFilterModel(model)}
                onSelectionModelChange={selected => setSelectedCandidates(selected)}
                onSortModelChange={(model) => setSortModel(model)}
                sortModel={sortModel}
              />
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

CandidateDashboardContainer.propTypes = {
  candidates: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
  guideId: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
};

CandidateDashboardContainer.defaultProps = {
  candidates: [],
};

export { CandidateDashboardContainer };
