import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Box,
  Button,
  ThemeProvider,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import ActionsDropdown from 'components/ActionsDropdown';
import GenericTabs from 'components/GenericTabs';
import PageHeader from 'components/PageHeader';
import PageActionsHeader from 'components/PageActionsHeader';
import SnackbarAlert from 'components/SnackbarAlert';
import DashboardTable from 'components/MailCenter/Partials/DashboardTable';
import MailCenterModal from 'components/MailCenter/Partials/MailCenterModal';
import fetchUtil from 'helpers/Fetch';
import BioQuestionClass from 'helpers/BioQuestionClass';
import useSavedTableSettings from 'hooks/useSavedTableSettings';
import theme from 'scripts/theme';

const useStyles = makeStyles({
  avatarContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  candidateIcon: {
    height: '2rem',
    width: '2rem',
    backgroundColor: '#fff',
    boxShadow: '0px 1px 4px #e5e5e5',
  },
  candidateDefaultIcon: {
    height: '1.25rem',
    width: '1.25rem',
    color: '#94A8B2',
  },
});

const DashboardManagementContainer = ({
  guideId,
  heading,
  subheading,
  messages,
  navigation,
  showSuccessMsgFrom,
}) => {
  // Constants
  const classes = useStyles();
  const TAB_NAMES = [
    messages.tabs.notInvited,
    messages.tabs.invited,
    messages.tabs.responded,
  ];
  const msgActs = messages.pageActions;
  // Candidate statuses
  const CandidateStatusCreated = { id: 1, name: 'Created' };
  const CandidateStatusInvited = { id: 2, name: 'Invited' };
  const CandidateStatusResponded = { id: 3, name: 'Responded' };

  const truncate = (str, length) => {
    if (str) {
      return str.length > length ? `${str.slice(0, length)}...` : str;
    }
    return '';
  };

  const hasEmail = (candidateObj) => {
    if (candidateObj.contactUser.candidateEmailStatus != 'BOUNCING') {
      return candidateObj.contactUser.email ? 'Yes' : 'No';
    }
    return 'Bounced';
  };

  /*
    PLACEHOLDER/COLUMN DATA
  */
  const notInvitedYet = {
    bulkActions: [
      { color: 'primary', name: messages.actions.sendEmail, onClick: () => handleSendEmail(selectedNotInvited, CandidateStatusCreated) },
      { color: 'primary', name: messages.actions.sendLetter, onClick: () => handleSendLetter(selectedNotInvited, CandidateStatusCreated) },
      { color: 'primary', name: messages.actions.moveInvited, onClick: () => handleMove(selectedNotInvited, CandidateStatusCreated, CandidateStatusInvited) },
      { color: 'primary', name: messages.actions.moveResponded, onClick: () => handleMove(selectedNotInvited, CandidateStatusCreated, CandidateStatusResponded) },
      // { color: 'primary', name: messages.actions.archive, onClick: () => handleArchiveCandidates(selectedNotInvited, CandidateStatusCreated) },
    ],
    columns: [
      {
        field: 'name',
        headerName: 'Name',
        flex: 2,
        renderCell: (params) => {
          const fullName = `${params.row.name != '' ? params.row.name : 'NO NAME'}`;
          const truncated = truncate(fullName, 60);
          return (
            <div className={classes.avatarContainer}>
              <Avatar
                className={classes.candidateIcon}
                alt={fullName}
              >
                <PersonIcon className={classes.candidateDefaultIcon} />
              </Avatar>
              <Box marginLeft={1}>
                <a href={`/races/${params.row.race.id}/candidates/${params.id}`}>
                  {truncated}
                </a>
              </Box>
            </div>
          );
        },
      },
      {
        field: 'raceName',
        headerName: 'Race/Measure',
        flex: 2,
        valueGetter: (params) => truncate(params.row?.race.name, 50),
      },
      {
        field: 'email',
        headerName: 'Has Email',
        type: 'string',
        flex: 1,
        valueGetter: (params) => hasEmail(params.row),
        renderCell: params => hasEmail(params.row),
      },
      {
        field: 'address',
        headerName: 'Mailing Address?',
        type: 'string',
        flex: 1,
        valueGetter: params => (params.row && BioQuestionClass.mailingAddressIsPresent(params.row.mailAddress) ? 'Yes' : 'No'),
        renderCell: params => (params.row && BioQuestionClass.mailingAddressIsPresent(params.row.mailAddress) ? 'Yes' : 'No'),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        width: 120,
        resizable: false,
        renderCell: (params) => {
          const options = [
            { label: messages.actions.editCandidate, onClick: () => handleEditCandidate(params.row.race.id, params.id) },
            { label: messages.actions.sendEmail, onClick: () => handleSendEmail([params.id], CandidateStatusCreated) },
            { label: messages.actions.sendLetter, onClick: () => handleSendLetter([params.id], CandidateStatusCreated) },
            { label: messages.actions.moveInvited, onClick: () => handleMove([params.id], CandidateStatusCreated, CandidateStatusInvited) },
            { label: messages.actions.moveResponded, onClick: () => handleMove([params.id], CandidateStatusCreated, CandidateStatusResponded) },
            // { label: messages.actions.archiveCandidate, onClick: () => handleArchiveCandidates([params.id], CandidateStatusCreated) },
          ];

          if (selectedNotInvited && selectedNotInvited.length > 0) {
            return <ActionsDropdown options={options} disabled={true} tooltip={messages.tooltipDisabledMenu} />;
          }

          return <ActionsDropdown options={options} />;
        },
      },
    ],
  };

  const invited = {
    bulkActions: [
      { color: 'primary', name: messages.actions.sendEmail, onClick: () => handleSendEmail(selectedInvited, CandidateStatusInvited) },
      { color: 'primary', name: messages.actions.sendLetter, onClick: () => handleSendLetter(selectedInvited, CandidateStatusInvited) },
      { color: 'primary', name: messages.actions.moveUninvited, onClick: () => handleMove(selectedInvited, CandidateStatusInvited, CandidateStatusCreated) },
      { color: 'primary', name: messages.actions.moveResponded, onClick: () => handleMove(selectedInvited, CandidateStatusInvited, CandidateStatusResponded) },
      // { color: 'primary', name: messages.actions.archive, onClick: () => handleArchiveCandidates(selectedInvited, CandidateStatusInvited) },
    ],
    columns: [
      {
        field: 'name',
        headerName: 'Name',
        flex: 2,
        renderCell: (params) => {
          const fullName = `${params.row.name != '' ? params.row.name : 'NO NAME'}`;
          const truncated = truncate(fullName, 60);
          return (
            <div className={classes.avatarContainer}>
              <Avatar
                className={classes.candidateIcon}
                alt={fullName}
              >
                <PersonIcon className={classes.candidateDefaultIcon} />
              </Avatar>
              <Box marginLeft={1}>
                <a href={`/races/${params.row.race.id}/candidates/${params.id}`}>
                  {truncated}
                </a>
              </Box>
            </div>
          );
        },
      },
      {
        field: 'raceName',
        headerName: 'Race/Measure',
        flex: 2,
        valueGetter: (params) => truncate(params.row?.race.name, 50),
      },
      {
        field: 'email',
        headerName: 'Has Email',
        type: 'string',
        flex: 1,
        valueGetter: params => hasEmail(params.row),
        renderCell: params => hasEmail(params.row),
      },
      {
        field: 'address',
        headerName: 'Mailing Address?',
        flex: 1,
        valueGetter: params => (params.row && BioQuestionClass.mailingAddressIsPresent(params.row.mailAddress) ? 'Yes' : 'No'),
        renderCell: params => (params.row && BioQuestionClass.mailingAddressIsPresent(params.row.mailAddress) ? 'Yes' : 'No'),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        resizable: false,
        sortable: false,
        width: 120,
        renderCell: (params) => {
          const options = [
            { label: messages.actions.editCandidate, onClick: () => handleEditCandidate(params.row.race.id, params.id) },
            { label: messages.actions.sendEmail, onClick: () => handleSendEmail([params.id], CandidateStatusInvited) },
            { label: messages.actions.sendLetter, onClick: () => handleSendLetter([params.id], CandidateStatusInvited) },
            { label: messages.actions.moveUninvited, onClick: () => handleMove([params.id], CandidateStatusInvited, CandidateStatusCreated) },
            { label: messages.actions.moveResponded, onClick: () => handleMove([params.id], CandidateStatusInvited, CandidateStatusResponded) },
            // { label: messages.actions.archiveCandidate, onClick: () => handleArchiveCandidates([params.id], CandidateStatusInvited) },
          ];

          if (selectedInvited && selectedInvited.length > 0) {
            return <ActionsDropdown options={options} disabled={true} tooltip={messages.tooltipDisabledMenu} />;
          }

          return <ActionsDropdown options={options} />;
        },
      },
    ],
  };

  const responded = {
    bulkActions: [
      { color: 'primary', name: messages.actions.moveBackUninvited, onClick: () => handleMove(selectedResponded, CandidateStatusResponded, CandidateStatusCreated) },
      { color: 'primary', name: messages.actions.sendEmail, onClick: () => handleSendEmail(selectedResponded, CandidateStatusResponded) },
      { color: 'primary', name: messages.actions.sendLetter, onClick: () => handleSendLetter(selectedResponded, CandidateStatusResponded) },
      // { color: 'primary', name: messages.actions.archive, onClick: () => handleArchiveCandidates(selectedResponded, CandidateStatusResponded) },
    ],
    columns: [
      {
        field: 'name',
        headerName: 'Name',
        flex: 2,
        renderCell: (params) => {
          const fullName = `${params.row.name != '' ? params.row.name : 'NO NAME'}`;
          const truncated = truncate(fullName, 60);
          return (
            <div className={classes.avatarContainer}>
              <Avatar
                className={classes.candidateIcon}
                alt={fullName}
              >
                <PersonIcon className={classes.candidateDefaultIcon} />
              </Avatar>
              <Box marginLeft={1}>
                <a href={`/races/${params.row.race.id}/candidates/${params.id}`}>
                  {truncated}
                </a>
              </Box>
            </div>
          );
        },
      },
      {
        field: 'raceName',
        headerName: 'Race/Measure',
        flex: 2,
        valueGetter: params => truncate(params.row?.race.name, 50),
      },
      {
        field: 'email',
        headerName: 'Has Email',
        type: 'string',
        flex: 1,
        valueGetter: params => hasEmail(params.row),
        renderCell: params => hasEmail(params.row),
      },
      {
        field: 'address',
        headerName: 'Mailing Address?',
        flex: 1,
        valueGetter: params => (params.row && BioQuestionClass.mailingAddressIsPresent(params.row.mailAddress) ? 'Yes' : 'No'),
        renderCell: params => (params.row && BioQuestionClass.mailingAddressIsPresent(params.row.mailAddress) ? 'Yes' : 'No'),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        resizable: false,
        sortable: false,
        width: 120,
        renderCell: (params) => {
          const options = [
            { label: messages.actions.editCandidate, onClick: () => handleEditCandidate(params.row.race.id, params.id) },
            { label: messages.actions.sendEmail, onClick: () => handleSendEmail([params.id], CandidateStatusResponded) },
            { label: messages.actions.sendLetter, onClick: () => handleSendLetter([params.id], CandidateStatusResponded) },
            // { label: messages.actions.archiveCandidate, onClick: () => handleArchiveCandidates([params.id], CandidateStatusResponded) },
            { label: messages.actions.moveUninvited, onClick: () => handleMove([params.id], CandidateStatusResponded, CandidateStatusCreated) },
          ];

          if (selectedResponded && selectedResponded.length > 0) {
            return <ActionsDropdown options={options} disabled={true} tooltip={messages.tooltipDisabledMenu} />;
          }

          return <ActionsDropdown options={options} />;
        },
      },
    ],
  };

  const [tab, setTab] = useState(0);
  const [template, setTemplate] = useState(-1);
  const [templates, setTemplates] = useState([]);
  const [templatesLoaded, setTemplatesLoaded] = useState(false);
  const [editTemplateState, setEditTemplateState] = useState({ open: false, submitting: false });
  const [sendEmailState, setSendEmailState] = useState({ open: false, candidateIds: [] });

  const [notInvitedPageSize, setNotInvitedPageSize] = useState(50);
  const [selectedNotInvited, setSelectedNotInvited] = useState([]);

  const [invitedPageSize, setInvitedPageSize] = useState(50);
  const [selectedInvited, setSelectedInvited] = useState([]);

  const [respondedPageSize, setRespondedPageSize] = useState(50);
  const [selectedResponded, setSelectedResponded] = useState([]);

  const [candidatesNotInvited, setCandidatesNotInvited] = useState({
    status: CandidateStatusCreated.name, candidates: [], loaded: false, loading: false,
  });
  const [candidatesInvited, setCandidatesInvited] = useState({
    status: CandidateStatusInvited.name, candidates: [], loaded: false, loading: false,
  });
  const [candidatesResponded, setCandidatesResponded] = useState({
    status: CandidateStatusResponded.name, candidates: [], loaded: false, loading: false,
  });

  const [notInvitedFilterModel, setNotInvitedFilterModel, notInvitedSortModel, setNotInvitedSortModel] = useSavedTableSettings({ key: 'not-invited-mail-center' });
  const [invitedFilterModel, setInvitedFilterModel, invitedSortModel, setInvitedSortModel] = useSavedTableSettings({ key: 'invited-mail-center' });
  const [respondedFilterModel, setRespondedFilterModel, respondedSortModel, setRespondedSortModel] = useSavedTableSettings({ key: 'responded-mail-center' });

  const _snackbar = useRef();

  const loadingData = () => {
    setTemplatesLoaded(false);

    fetchUtil(`/api/v1/guide/${guideId}/email-template`, 'GET', {})
      .then(response => {
        const templatesDistinctified = response.map(t => ({
          ...t,
          name: t.name === 'Default' ? `${t.type.name} ${t.name}` : t.name,
        }));
        templatesDistinctified.sort((a, b) => (a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1));
        setTemplates(templatesDistinctified);
        if (response && response.length > 0) {
          setTemplate(templatesDistinctified[0].id);
        }
        setTemplatesLoaded(true);
      })
      .catch(error => {
        _snackbar.current.show('error', messages.errors.retrieveTemplates);
        console.error(error);
        setTemplatesLoaded(true);
      });

    loadCandidatesByStatus(CandidateStatusCreated);
    // Show success message
    checkSuccessMsg();
  };

  const checkSuccessMsg = () => {
    // Note: This method was updated to check for a generic parameter that indicates which page the success message comes from.
    if (showSuccessMsgFrom == 'sendEmail') {
      _snackbar.current.show('success', messages.successSendEmail);
    } else if (showSuccessMsgFrom == 'editTemplate') {
      _snackbar.current.show('success', messages.successEditTemplate);
    } else if (showSuccessMsgFrom == 'deleteTemplate') {
      _snackbar.current.show('success', 'Email template was deleted successfully.');
    }
  };

  const checkHasEmailAddress = (candidate) => candidate?.contactUser?.email && candidate?.contactUser?.email?.trim() != '';
  const checkHasMailingAddress = (candidate) => candidate && candidate.contactUser && BioQuestionClass.mailingAddressIsPresent(candidate.mailAddress, true);

  const loadCandidatesByStatus = (status) => {
    const { objCandidateStatus, setObjCandidateStatus } = getObjCandidatesByStatus(status);
    fetchCandidatesByStatus(objCandidateStatus, setObjCandidateStatus);
  };

  const fetchCandidatesByStatus = (objCandidateStatus, setObjCandidateStatus) => {
    const url = `/api/v1/guide/${guideId}/candidate?state=${objCandidateStatus.status}`;
    setObjCandidateStatus(ps => ({ ...ps, loading: true }));

    fetchUtil(url, 'GET', {})
      .then(result => {
        const copyObjCs = { ...objCandidateStatus };
        copyObjCs.candidates = result;
        copyObjCs.loaded = true;
        copyObjCs.loading = false;
        setObjCandidateStatus(copyObjCs);
      }).catch(error => {
        console.error(error);
      });
  };

  const handleSetOpenEditTemplates = (open) => {
    const copyState = { ...editTemplateState };
    copyState.open = open;
    setEditTemplateState(copyState);
  };

  const handleConfirmEditTemplate = () => {
    setEditTemplateState(ps => ({ ...ps, submitting: true }));
    window.location.href = `${navigation.editTemplate}?id=${template}`;
  };

  const handleSetOpenSendEmail = (open, candidateIds) => {
    const copyState = { ...sendEmailState };
    copyState.open = open;
    copyState.candidateIds = open ? candidateIds : null;
    setSendEmailState(copyState);
  };

  const handleConfirmSendEmail = () => {
    const sendEmailUrl = `${navigation.sendEmail}?candidateIds=${JSON.stringify(sendEmailState.candidateIds)}&templateId=${template}`;
    handleSetOpenSendEmail(false, null);
    window.location.href = sendEmailUrl;
  };

  const handleConfirmSendLetter = (candidateIds) => {
    const sendLetterUrl = `${navigation.sendLetter}?candidateIds=${JSON.stringify(candidateIds)}`;
    window.location.href = sendLetterUrl;
  };

  const handleTabChange = (event, value) => {
    if (value == 0) {
      loadCandidatesByStatus(CandidateStatusCreated);
    } else if (value == 1) {
      loadCandidatesByStatus(CandidateStatusInvited);
    } else if (value == 2) {
      loadCandidatesByStatus(CandidateStatusResponded);
    }

    setTab(value);
  };

  const handleCreateTemplate = () => {
    window.location.href = navigation.createTemplate;
  };

  const getObjCandidatesByStatus = (status) => {
    if (status.id == CandidateStatusCreated.id) {
      return { objCandidateStatus: candidatesNotInvited, setObjCandidateStatus: setCandidatesNotInvited };
    }

    if (status.id == CandidateStatusInvited.id) {
      return { objCandidateStatus: candidatesInvited, setObjCandidateStatus: setCandidatesInvited };
    }

    if (status.id == CandidateStatusResponded.id) {
      return { objCandidateStatus: candidatesResponded, setObjCandidateStatus: setCandidatesResponded };
    }
  };

  const handleEditCandidate = (raceId, candidateId) => {
    // TODO: eventually the routes should be races/ or measures/
    // When that happen, pass in clazz and set conditional links
    // This update should happen anywhere you are viewing a candidate detail screen
    window.location.href = `/races/${raceId}/candidates/${candidateId}`;
  };

  const handleSendEmail = (ids, status) => {
    const { candidates } = getObjCandidatesByStatus(status).objCandidateStatus;
    let canSend = true;

    candidates.forEach(c => {
      if (ids.includes(c.id) && !checkHasEmailAddress(c)) {
        canSend = false;
      }
    });

    if (canSend) {
      handleSetOpenSendEmail(true, ids);
      return;
    }

    _snackbar.current.show('error', messages.sendEmail.invalid);
  };

  const handleSendLetter = (ids, status) => {
    let canSend = true;
    const { candidates } = getObjCandidatesByStatus(status).objCandidateStatus;

    candidates.forEach(c => {
      if (ids.includes(c.id) && !checkHasMailingAddress(c)) {
        canSend = false;
      }
    });

    if (canSend) {
      handleConfirmSendLetter(ids);
    } else {
      // handleConfirmSendLetter(ids);
      _snackbar.current.show('error', messages.sendLetter.invalid);
    }
  };

  const handleMove = async (ids, fromState, toState) => {
    try {
      // turn on loading indicator
      const currentDashboardTab = getObjCandidatesByStatus(fromState);
      currentDashboardTab.setObjCandidateStatus(ps => ({ ...ps, loading: true }));

      const METHOD = 'PUT';
      const URL = `/api/v1/guide/${guideId}/candidate/statuses`;
      const BODY = {
        candidateIds: ids,
        candidateState: toState.id,
        oldState: fromState.id,
      };

      await fetchUtil(URL, METHOD, BODY);

      // re-load from database after move
      loadCandidatesByStatus(fromState);
    } catch (e) {
      console.error(e);
      _snackbar.current.show('error', 'There was an error updating these candidates. Please try again.');
      const currentDashboardTab = getObjCandidatesByStatus(fromState);
      currentDashboardTab.setObjCandidateStatus(ps => ({ ...ps, loading: false }));
    }
  };

  // const handleArchiveCandidates = (ids, state) => {
  //   // TODO: This process should be updated when the API endpoint is created. Now, this only filters the candidates to archive.
  //   const { candidates } = getObjCandidatesByStatus(state).objCandidateStatus;
  //   const candidatesToArchive = candidates.filter(c => ids.includes(c.id));
  //   console.log(candidatesToArchive);
  // };

  useEffect(() => {
    // show success from create template page
    const url = new URL(window.location.href);

    if (url.searchParams.has('from') && url.searchParams.get('from') == 'createtemplate') {
      _snackbar.current.show('success', messages.createTemplate.success);
    }

    if (url.searchParams.has('from') && url.searchParams.get('from') == 'edittemplate') {
      _snackbar.current.show('success', messages.editTemplate.success);
    }

    loadingData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarAlert ref={_snackbar} />

      <PageHeader heading={heading} subheading={subheading} />

      <PageActionsHeader>
        <Button
          className="ga-ml-8"
          color='secondary'
          onClick={() => handleSetOpenEditTemplates(true)}
          size="small"
          variant="contained"
        >
          {msgActs.editTemplates}
        </Button>
        <Button
          className="ga-ml-8"
          color='secondary'
          onClick={handleCreateTemplate}
          size="small"
          variant="contained"
        >
          {msgActs.createTemplate}
        </Button>
      </PageActionsHeader>

      <div className="ga-container">
        <div className="mdc-layout-grid">
          <GenericTabs
            ariaLabel={messages.tabs.aria}
            identifier="mail-dashboard-tabs"
            onChange={handleTabChange}
            selected={tab}
            tabs={TAB_NAMES}
          >
            <DashboardTable
              addressFilter={{
                options: [messages.filters.yes, messages.filters.no],
                default: messages.filters.address,
              }}
              bulkActions={notInvitedYet.bulkActions}
              candidates={candidatesNotInvited.candidates}
              columns={notInvitedYet.columns}
              filterByText={messages.filters.label}
              filterModel={notInvitedFilterModel}
              loadingCandidates={candidatesNotInvited.loading}
              onFilterModelChange={(model) => setNotInvitedFilterModel(model)}
              onSortModelChange={(model) => setNotInvitedSortModel(model)}
              pageSize={notInvitedPageSize}
              searchPlaceholder={messages.filters.search}
              selected={selectedNotInvited}
              setPageSize={setNotInvitedPageSize}
              setSelected={setSelectedNotInvited}
              sortModel={notInvitedSortModel}
            />

            <DashboardTable
              bulkActions={invited.bulkActions}
              candidates={candidatesInvited.candidates}
              columns={invited.columns}
              filterModel={invitedFilterModel}
              loadingCandidates={candidatesInvited.loading}
              onFilterModelChange={(model) => setInvitedFilterModel(model)}
              onSortModelChange={(model) => setInvitedSortModel(model)}
              pageSize={invitedPageSize}
              selected={selectedInvited}
              setPageSize={setInvitedPageSize}
              setSelected={setSelectedInvited}
              sortModel={invitedSortModel}
            />

            <DashboardTable
              bulkActions={responded.bulkActions}
              candidates={candidatesResponded.candidates}
              columns={responded.columns}
              filterModel={respondedFilterModel}
              loadingCandidates={candidatesResponded.loading}
              onFilterModelChange={(model) => setRespondedFilterModel(model)}
              onSortModelChange={(model) => setRespondedSortModel(model)}
              pageSize={respondedPageSize}
              selected={selectedResponded}
              setPageSize={setRespondedPageSize}
              setSelected={setSelectedResponded}
              sortModel={respondedSortModel}
            />
          </GenericTabs>
        </div>
      </div>

      {templatesLoaded && (
        <>
          {/* Edit Template */}
          <MailCenterModal
            messages={messages.editTemplate}
            onCancel={() => handleSetOpenEditTemplates(false)}
            onConfirm={handleConfirmEditTemplate}
            onSetTemplate={setTemplate}
            openEditTemplates={editTemplateState.open}
            submitting={editTemplateState.submitting}
            template={templates.find(t => t.id === template)}
            templates={templates}
            templatesLoaded={templatesLoaded}
          />
          {/* Send Email */}
          <MailCenterModal
            messages={messages.sendEmail}
            onCancel={() => handleSetOpenSendEmail(false, null)}
            onConfirm={handleConfirmSendEmail}
            onSetTemplate={setTemplate}
            openEditTemplates={sendEmailState.open}
            submitting={sendEmailState.submitting}
            template={templates.find(t => t.id === template)}
            templates={templates}
            templatesLoaded={templatesLoaded}
          />
        </>
      )}
    </ThemeProvider>
  );
};

DashboardManagementContainer.propTypes = {
  guideId: PropTypes.string,
  heading: PropTypes.string,
  messages: PropTypes.object.isRequired,
  navigation: PropTypes.object,
  showSuccessMsgFrom: PropTypes.string,
  subheading: PropTypes.string,
};

DashboardManagementContainer.defaultProps = {
  heading: 'Mail Center',
  showSuccessMsgFrom: '',
  subheading: 'Communicate with candidates through email or snail mail.',
};

export default DashboardManagementContainer;
