import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { CircularProgress, Typography } from '@material-ui/core';
import theme from 'scripts/theme';
import ConfirmationDialog from 'components/ConfirmationDialog';
import PageHeader from 'components/PageHeader';
import SnackbarAlert from 'components/SnackbarAlert';
import RaceQuestionSetForm from 'components/RaceQuestions/RaceQuestionSetForm';
import RaceQuestionSetAccordion from 'components/RaceQuestions/RaceQuestionSetAccordion';

const styles = {
  divider: {
    margin: '2.4rem 0 2.6rem',
  },
  listHeading: {
    marginBottom: '2.4rem',
  },
};

class RaceQuestionManagement extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmationDialogBody: {},
      confirmationDialogHeading: '',
      confirmationDialogId: null,
      loading: true,
      submitting: false,
      questionSets: [],
      showConfirmationDialog: false,
    };

    this._snackbar = React.createRef();

    this.handleError = this.handleError.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleQuestionSetDelete = this.handleQuestionSetDelete.bind(this);
    this.handleCancelDeleteConfirmation = this.handleCancelDeleteConfirmation.bind(this);
    this.handleConfirmDeleteConfirmation = this.handleConfirmDeleteConfirmation.bind(this);
  }

  componentDidMount() {
    Promise.all([this.fetchQuestionSets()])
      .then(() => {
        this.setState({
          loading: false,
        });
      })
      .catch(error => {
        console.error(error.message);

        this.setState({
          loading: false,
          error,
        });
      });
  }

  fetchQuestionSets() {
    const { getUrl } = this.props;

    this.setState({
      loading: true,
    });

    return fetch(getUrl)
      .then(res => res.json())
      .then((questionSets) => {
        this.setState({
          questionSets,
        });
      });
  }

  handleQuestionSetDelete(id, name) {
    const dialogHeading = `Delete ${name}?`;
    const dialogBody = (
      <div>
        <Typography component="p" variant="body1" paragraph>
          Are you sure you want to delete the <strong>{name}</strong> question set? Individual answers to questions in this set <strong>will be unaffected</strong> by this action.
        </Typography>

        <Typography component="p" variant="body1">
          <em><strong>NOTE:</strong> If candidate answers have already been received for questions in this set, the set can be restored from the recycling bin.</em>
        </Typography>
      </div>
    );

    this.setState({
      showConfirmationDialog: true,
      confirmationDialogBody: dialogBody,
      confirmationDialogHeading: dialogHeading,
      confirmationDialogId: id,
    });
  }

  handleCancelDeleteConfirmation() {
    this.setState({
      confirmationDialogId: null,
      showConfirmationDialog: false,
    });
  }

  handleConfirmDeleteConfirmation() {
    const { deleteUrl } = this.props;
    const { confirmationDialogId } = this.state;
    const csrfToken = document.querySelector('input[name="csrfToken"]').value;

    this.setState({
      submitting: true,
    });

    fetch(`${deleteUrl}/${confirmationDialogId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'Csrf-Token': csrfToken,
      },
    })
      .then(response => {
        const { status } = response;

        // If we get a 403, we assume user has been logged out and
        // redirect them to the login page.
        if (status === 403) {
          this.handleError('FORBIDDEN');
        }

        if (response.redirected == true) {
          window.location.replace(response.url);
        }

        if (response.ok) {
          console.log('SUCCESS: ', response);

          this.handleSuccess('DELETE', { id: confirmationDialogId });

          this.setState({
            showConfirmationDialog: false,
            submitting: false,
          });
        } else {
          throw new Error(response);
        }
      })
      .catch(error => {
        console.error(error);

        this.setState({
          showConfirmationDialog: false,
          submitting: false,
        });

        this.handleError('DELETE');
      });
  }

  handleError(action, message) {
    const { messages } = this.props;
    let snackbarMessage;

    this._snackbar.current.hide();

    if (message) {
      this._snackbar.current.show('error', message);
      return;
    }

    switch (action) {
      case 'POST':
        snackbarMessage = messages.questionSet.add.error;
        break;
      case 'PATCH':
        snackbarMessage = messages.questionSet.edit.error;
        break;
      case 'DELETE':
        snackbarMessage = messages.questionSet.delete.error;
        break;
      case 'FORBIDDEN':
        snackbarMessage = messages.forbidden;
        break;
      default:
        snackbarMessage = messages.common.error.unknown;
        break;
    }

    this._snackbar.current.show('error', snackbarMessage);
  }

  handleSuccess(action, data) {
    const { messages } = this.props;
    const { questionSets } = this.state;
    let message = messages.common.success;
    let updatedQuestionSets;

    this._snackbar.current.hide();

    switch (action) {
      case 'PATCH':
        updatedQuestionSets = questionSets.slice(0);
        updatedQuestionSets.forEach(record => {
          if (record.id === data.id) {
            record.name = data.name;
          }
        });
        message = messages.questionSet.edit.success;

        this.setState({
          questionSets: updatedQuestionSets,
        });

        this._snackbar.current.show('success', message);
        break;
      case 'DELETE':
        updatedQuestionSets = questionSets.filter(record => record.id !== data.id);
        message = messages.questionSet.delete.success;

        this.setState({
          questionSets: updatedQuestionSets,
        });

        this._snackbar.current.show('success', message);
        break;
      case 'POST':
      default:
        // updatedQuestionSets = questionSets.slice(0);
        // updatedQuestionSets.push(data);
        // message = messages.questionSet.add.success;

        window.location.href = `/questions/${data.id}`;
        break;
    }
  }

  displayQuestionSetList() {
    const { messages, languages, questionSetRootUrl } = this.props;
    const { loading, questionSets } = this.state;

    if (loading) {
      return (
        <div>
          <CircularProgress />
        </div>
      );
    }

    return questionSets.map((set) => (
      <RaceQuestionSetAccordion
        key={set.id}
        messages={messages}
        languages={languages}
        onDelete={this.handleQuestionSetDelete}
        questionSetRootUrl={questionSetRootUrl}
        set={set}
      />
    ));
  }

  render() {
    const {
      breadcrumbText,
      breadcrumbUrl,
      heading,
      subheading,
      classes,
      messages,
      languages,
      postUrl,
    } = this.props;
    const {
      confirmationDialogBody,
      confirmationDialogHeading,
      showConfirmationDialog,
      submitting,
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <SnackbarAlert ref={this._snackbar} />

        <PageHeader
          breadcrumbText={breadcrumbText}
          breadcrumbUrl={breadcrumbUrl}
          heading={heading}
          subheading={subheading}
        />

        <div className="ga-container">
          <div className="mdc-layout-grid">
            <div className="mdc-layout-grid__inner">
              <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">

                <ConfirmationDialog
                  cancelButtonText={messages.common.button.cancel}
                  confirmButtonText={messages.questionSet.delete.confirm.button}
                  heading={confirmationDialogHeading}
                  onCancel={this.handleCancelDeleteConfirmation}
                  onConfirm={this.handleConfirmDeleteConfirmation}
                  open={showConfirmationDialog}
                  submitting={submitting}
                >
                  {confirmationDialogBody}
                </ConfirmationDialog>

                <Typography component="h2" variant="h2" paragraph>
                  {messages.questionSet.add.heading}
                </Typography>

                <RaceQuestionSetForm
                  buttonText={messages.questionSet.add.button}
                  languages={languages}
                  onError={this.handleError}
                  onSuccess={this.handleSuccess}
                  outlined
                  requiredText={messages.questionSet.add.required}
                  submitMethod="POST"
                  submitUrl={postUrl}
                  validationErrorMessage={messages.questionSet.add.error}
                />

                <hr className={classes.divider} />

                <div className={classes.listHeading}>
                  <Typography component="h2" variant="h2">
                    {messages.questionSet.heading}
                  </Typography>

                  <Typography component="p" variant="body1">
                    {messages.questionSet.subheading}
                  </Typography>
                </div>

                {this.displayQuestionSetList()}
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}

RaceQuestionManagement.propTypes = {
  breadcrumbText: PropTypes.string,
  breadcrumbUrl: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  classes: PropTypes.object,
  deleteUrl: PropTypes.string.isRequired,
  getUrl: PropTypes.string.isRequired,
  languages: PropTypes.array.isRequired,
  messages: PropTypes.object,
  postUrl: PropTypes.string.isRequired,
  questionSetRootUrl: PropTypes.string.isRequired,
};

RaceQuestionManagement.defaultProps = {
  breadcrumbText: 'Races & Measures',
  breadcrumbUrl: '/races',
  heading: 'Race Questions',
  subheading: 'Explains what a race question is and how to use it. Check out our help guide.',
  messages: {
    common: {
      button: {
        cancel: 'Cancel',
        delete: 'Delete',
      },
      error: {
        unknown: 'An unknown error occurred. Please contact your guide admin.',
      },
    },
    questionSet: {
      heading: 'Existing race question sets',
      subheading: 'Manage your existing race question sets',
      add: {
        heading: 'Add additional race question sets',
        required: '*Required fields needed to add a new question set',
        button: 'Add Question Set',
        error: 'There was an issue adding your question set. Correct any errors and try again.',
        success: 'Your question set was added successfully!',
      },
      delete: {
        button: 'Delete',
        confirm: {
          button: 'Yes, I\'m sure',
        },
        error: 'There was an issue deleting your question set. Contact your guide admin if this issue continues.',
        success: 'Question set was successfully deleted!',
      },
      edit: {
        button: 'Edit',
        error: 'There was an issue updating your question set. Try again or contact your guide admin if the issue continues.',
        modal: {
          button: 'Update question set',
          heading: 'Editing question set',
          required: '*Required fields needed for your question set',
        },
        success: 'Question set updated successfully!',
      },
      list: {
        heading: 'Question list',
        subheading: 'It\'s recommended to ask candidates 3-5 questions.',
      },
      unbiasedQuestions: {
        button: 'View',
        heading: 'How to write unbiased questions',
        subheading: 'You can revisit our suggestions for how to write unbiased questions at any time.',
      },
      update: {
        button: 'Update',
      },
    },
    raceQuestions: {
      translation: {
        empty: {
          heading: 'There are no translated questions for this language.',
          add: 'Add translations',
        },
      },
    },
  },
};

export default withStyles(styles)(RaceQuestionManagement);
