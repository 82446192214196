import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import theme from '../theme';

const useStyles = makeStyles({
  emptyStateContainer: {
    backgroundColor: '#ededed',
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.4rem',
    color: '#999',
    padding: '3rem 10rem',
    borderRadius: '4px',
  },
  emptyState: {
    textAlign: 'center',
    lineHeight: '2rem',
  },
  error: {
    color: '#ac1b3d',
  },
});

function EmptyState({ children, error }) {
  const classes = useStyles();

  return (
    <div className={classes.emptyStateContainer} data-testid="empty-state-container">
      <div className={`${classes.emptyState} ${error && classes.error}`}>
        {children}
      </div>
    </div>
  );
}

EmptyState.propTypes = {
  children: PropTypes.node,
  error: PropTypes.bool,
};

EmptyState.defaultProps = {
  error: false,
};

export default EmptyState;
