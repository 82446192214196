import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';

function LanguagesAppliedToGuide(props) {
  const displayLanguages = () => {
    const { languages } = props;
    const langLabels = languages.map(lang => lang.label);
    langLabels.unshift('English (default)');

    return (
      <div>
        <Typography component="h3" variant="h3" paragraph>
          Languages currently applied to the guide
        </Typography>
        <Typography component="p" variant="body2" paragraph>
          {langLabels.join(', ')}
        </Typography>
      </div>
    );
  };

  return (
    <div>
      {displayLanguages()}

      <div>
        <EmojiObjectsOutlinedIcon fontSize="large" />

        <Typography component="p" variant="body1">
          To learn more about additional languages or to add more options, please adjust your <a href="/settings/languages">guide settings</a>.
        </Typography>
      </div>
    </div>
  );
}

LanguagesAppliedToGuide.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
  })),
};

LanguagesAppliedToGuide.defaultProps = {
  languages: [],
};

export default LanguagesAppliedToGuide;
