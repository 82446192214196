import React from 'react';
import PropTypes from 'prop-types';
import clone from 'rfdc/default';
import { withStyles } from '@material-ui/styles';
import {
  AccordionDetails,
  AccordionSummary,
  Button,
  Paper,
  Typography,
} from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LanguageTabsDisplay from 'components/RaceQuestions/LanguageTabsDisplay';
import theme from 'scripts/theme';

const styles = {
  accordionHeading: {
    fontWeight: 'bold',
  },
  accordionContent: {
    overflow: 'hidden',
    width: '100%',
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '1rem',
  },
  tabCard: {
    padding: theme.spacing(3),
    margin: theme.spacing(1),
  },
  actionButton: {
    minWidth: '75px',
  },
  destructiveButton: {
    marginRight: '1rem',
    minWidth: '75px',
    color: '#ac1b3d',
    '&:hover': {
      color: '#6B1126',
    },
  },
};

const Accordion = withStyles({
  root: {
    borderWidth: '0 0 1px 0',
    borderColor: '#e5e5e5',
    borderRadius: '0',
    borderStyle: 'solid',
    boxShadow: 'none',
    '&:last-child': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

class RaceQuestionSetAccordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete() {
    const { onDelete, set } = this.props;
    onDelete(set.id, set.name);
  }

  render() {
    const {
      classes, messages, languages, questionSetRootUrl, set,
    } = this.props;
    const questionSetUrl = `${questionSetRootUrl}/${set.id}`;

    const questionsSorted = clone(set.questions);
    questionsSorted.sort((a, b) => a.index - b.index);

    return (
      <Accordion>
        <AccordionSummary
          aria-controls={set.id}
          expandIcon={<ExpandMoreIcon />}
          id={set.id}
        >
          <Typography className={classes.accordionHeading} component="h3" variant="h4">
            {set.name}
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <div className={classes.accordionContent}>
            <Paper elevation={3} className={classes.tabCard}>
              <LanguageTabsDisplay
                languages={languages}
                questionSetRootUrl={questionSetRootUrl}
                id={set.id}
                name={set.name}
                questions={questionsSorted}
              />
            </Paper>
          </div>
        </AccordionDetails>

        <div className={classes.actionBar}>
          <Button
            className={classes.destructiveButton}
            color="secondary"
            onClick={this.handleDelete}
            size="large"
          >
            {messages.common.button.delete}
          </Button>

          <Button
            className={classes.actionButton}
            color="primary"
            href={questionSetUrl}
            size="large"
          >
            {messages.common.button.edit}
          </Button>
        </div>
      </Accordion>
    );
  }
}

RaceQuestionSetAccordion.propTypes = {
  classes: PropTypes.object,
  languages: PropTypes.array,
  messages: PropTypes.shape({
    common: PropTypes.shape({
      button: PropTypes.shape({
        delete: PropTypes.string,
        edit: PropTypes.string,
      }),
    }),
    raceQuestions: PropTypes.shape({
      translation: PropTypes.shape({
        empty: PropTypes.shape({
          heading: PropTypes.string,
          add: PropTypes.string,
        }),
      }),
    }),
  }),
  onDelete: PropTypes.func,
  questionSetRootUrl: PropTypes.string.isRequired,
  set: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    questions: PropTypes.arrayOf(PropTypes.shape),
  }),
};

RaceQuestionSetAccordion.defaultProps = {
  deleteButtonText: 'Delete',
  messages: {
    common: {
      button: {
        delete: 'Delete',
        edit: 'Edit',
      },
    },
    raceQuestions: {
      translation: {
        empty: {
          heading: '',
          add: '',
        },
      },
    },
  },
  onDelete: () => {},
  languages: [],
  set: {},
  questionSetRootUrl: '/questions',
};

export default withStyles(styles)(RaceQuestionSetAccordion);
