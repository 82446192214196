import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Box,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import ConfirmationDialog from 'components/ConfirmationDialog';
import PageActionsHeader from 'components/PageActionsHeader';
import PageActionsFooter from 'components/PageActionsFooter';
import PageHeader from 'components/PageHeader';
import SnackbarAlert from 'components/SnackbarAlert';
import defaultTemplateValues from 'components/MailCenter/Partials/DefaultTemplateValues';
import EmailForm from 'components/MailCenter/Partials/EmailForm';
import fetchUtil from 'helpers/Fetch';
import GaValidations from 'helpers/GaValidations';
import theme from 'scripts/theme';

const CreateTemplateContainer = ({
  breadcrumbText,
  breadcrumbUrl,
  messages,
  templateTypes,
  guideId,
}) => {
  const _snackbar = useRef();
  // Generic state
  const [submitting, setSubmitting] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({}); // add setFieldErrors when API call is implemented
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [templateTypesList] = useState(templateTypes.map(t => (
    { ...t, value: t.id, label: t.name }
  )));

  // Fields' state
  const [templateType, setTemplateType] = useState(templateTypes[0].id); // replace with default
  const [templateName, setTemplateName] = useState('');
  const [senderName, setSenderName] = useState('');
  const [responder, setResponder] = useState('');
  const [bcc, setBCC] = useState('');
  const [subject, setSubject] = useState('');
  const [savedWYSIWYGValue, setSavedWYSIWYGValue] = useState('<p></p>');
  const [renderedWYSIWYGValue, setRenderedWYSIWYGValue] = useState('<p></p>');
  const responderEmailId = 'responderEmail';
  const bccId = 'bcc';

  const handleWYSIWYGChange = (htmlState) => {
    setSavedWYSIWYGValue(htmlState);
  };

  const confirmCancelDialog = () => {
    window.location.assign('/mail');
  };

  // function to redirect to main menu on cancel, with confirm dialog
  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const isValidEmailTemplate = () => {
    const errors = {};
    // checking responder email
    GaValidations.localValidations(
      responderEmailId,
      messages.fields.responder.label,
      [GaValidations.IsEmail],
      responder,
      errors,
    );
    // checking bcc email
    GaValidations.localValidations(
      bccId,
      messages.fields.bcc.label,
      [GaValidations.IsEmail],
      bcc,
      errors,
    );
    // Setting errors
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return false;
    }
    return true;
  };

  // Async function to save the template to the database, and redirect to the dashboard
  const handleSave = async () => {
    if (!isValidEmailTemplate()) {
      _snackbar.current.show('error', messages.errors.errorCreateTemplate);
      return;
    }

    setSubmitting(true);
    try {
      await fetchUtil(`/api/v1/guide/${guideId}/email-template`, 'POST', {
        name: templateName,
        subject,
        responderEmail: responder,
        senderName,
        bcc,
        body: savedWYSIWYGValue,
        emailTemplateTypeValue: templateType,
      });
      // url param to show snackbar on the dashboard
      window.location.replace('/mail?from=createtemplate');
    } catch (e) {
      // ERROR HANDLING HERE (isn't that so easy, nice work everyone)
      setFieldErrors(e);
      console.error(e);
      _snackbar.current.show('error', messages.errors.errorCreateTemplate);
    }

    setSubmitting(false);
  };

  // ON LOAD
  useEffect(() => {
    // initially populate for invitation type
    handleTemplateTypeChange(templateTypes[0].id);
  }, []);

  const handleTemplateTypeChange = newValue => {
    setTemplateType(newValue);

    // Changes fields to new default values based on DefaultTemplateValues.js
    const newDefaults = defaultTemplateValues[newValue];
    if (typeof newDefaults !== 'undefined') {
      Object.keys(newDefaults).forEach(fieldName => {
        const defaultValue = newDefaults[fieldName];
        switch (fieldName) {
          case 'subject':
            setSubject(defaultValue);
            break;
          case 'body':
            // must be HTML
            handleWYSIWYGChange(defaultValue);
            setRenderedWYSIWYGValue(defaultValue);
            break;
          // add more cases here as needed
          default:
            console.warn('Unsuppored field passed to template type defaults: ', fieldName);
        }
      });
    }
  };

  // CONSTANTS
  // See FormFieldRenderer.js for usage
  const fields = [
    {
      id: 'type',
      value: templateType,
      handleValueChange: (ev) => handleTemplateTypeChange(ev.target.value),
      label: messages.fields.type.label,
      readOnly: false,
      type: 'select',
      options: templateTypesList, // passed from scala
      width: 'full',
      required: true,
    },
    {
      id: 'name',
      value: templateName,
      handleValueChange: (ev) => setTemplateName(ev.target.value),
      label: messages.fields.name.label,
      readOnly: false,
      type: 'text',
      width: 'full',
      required: true,
    },
    {
      id: 'senderName',
      value: senderName,
      handleValueChange: (ev) => setSenderName(ev.target.value),
      label: messages.fields.sender.label,
      help: messages.fields.sender.help,
      readOnly: false,
      type: 'text',
      width: 'full',
      required: true,
    },
    {
      id: responderEmailId,
      value: responder,
      handleValueChange: (ev) => setResponder(ev.target.value),
      label: messages.fields.responder.label,
      help: messages.fields.responder.help,
      readOnly: false,
      type: 'email',
      width: 'half',
      required: true,
    },
    {
      id: bccId,
      value: bcc,
      handleValueChange: (ev) => setBCC(ev.target.value),
      label: messages.fields.bcc.label,
      help: messages.fields.bcc.help,
      readOnly: false,
      type: 'multi-email',
      width: 'half',
      required: false,
    },
    {
      id: 'subject',
      value: subject,
      handleValueChange: (ev) => setSubject(ev.target.value),
      label: messages.fields.subject.label,
      readOnly: false,
      type: 'text',
      width: 'full',
      required: true,
    },
  ];

  const macros = [
    {
      title: '#question_list()',
      description: messages.macros.questionList,
    },
    {
      title: '#question_responses()',
      description: messages.macros.questionsResponses,
    },
    {
      title: '#mailing_address()',
      description: messages.macros.mailingAddress,
    },
    {
      title: '#today()',
      description: messages.macros.today,
    },
    {
      title: '#candidate_name()',
      description: messages.macros.candidateName,
    },
    {
      title: '#contact_name()',
      description: messages.macros.contactName,
    },
    {
      title: '#race_name()',
      description: messages.macros.raceName,
    },
    // hiding fof launch
    // {
    //   title: '#race_url()',
    //   description: messages.macros.raceURL,
    // },
    // {
    //   title: '#candidate_url()',
    //   description: messages.macros.candidateURL,
    // },
    {
      title: '#response_link()',
      description: messages.macros.candidateResponseURL,
    },
    // {
    //   title: '#response_url_short()',
    //   description: messages.macros.candidateResponseURLShort,
    // },
    {
      title: '#security_code()',
      description: messages.macros.securityCode,
    },
  ];

  return (
    <div>
      <ThemeProvider theme={theme}>
        <SnackbarAlert ref={_snackbar} />

        {/* Cancel confirm */}
        <ConfirmationDialog
          cancelButtonText={messages.cancelDialog.cancel}
          confirmButtonText={messages.cancelDialog.confirm}
          heading={messages.cancelDialog.heading}
          onCancel={() => setOpenCancelDialog(false)}
          onConfirm={() => confirmCancelDialog()}
          open={openCancelDialog}
        >
          <Typography component="p" variant="body1">
            {messages.cancelDialog.body}
          </Typography>
        </ConfirmationDialog>

        <PageHeader
          breadcrumbText={breadcrumbText}
          breadcrumbUrl={breadcrumbUrl}
          heading={messages.title}
          subheading={messages.supporting}
        />

        {/* Page Actions */}
        <PageActionsHeader>
          <Button
            color="primary"
            onClick={handleCancel}
            disabled={submitting}
          >
            {messages.actions.cancel}
          </Button>

          <Button
            color="secondary"
            onClick={handleSave}
            disabled={submitting}
            variant="contained"
          >
            {messages.actions.save}
          </Button>
        </PageActionsHeader>

        <div className="ga-container">
          <div className="mdc-layout-grid">
            <div className="mdc-layout-grid__inner">
              <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                <Box marginTop={2} marginBottom={5}>
                  <EmailForm
                    fields={fields}
                    macros={macros}
                    errors={fieldErrors}
                    defaultWYSIWYG={renderedWYSIWYGValue}
                    onWYSIWYGChange={handleWYSIWYGChange}
                    submitting={submitting}
                    wysiwygHeading={messages.template.title}
                    macrosTitle={messages.template.help}
                  />
                </Box>
              </div>
            </div>
          </div>
        </div>

        {/* Page Actions Part Dos */}
        <PageActionsFooter sticky>
          <Button
            color="primary"
            onClick={handleCancel}
            disabled={submitting}
          >
            {messages.actions.cancel}
          </Button>

          <Button
            color="secondary"
            onClick={handleSave}
            disabled={submitting}
            variant="contained"
          >
            {messages.actions.save}
          </Button>
        </PageActionsFooter>

      </ThemeProvider>
    </div>
  );
};

CreateTemplateContainer.propTypes = {
  breadcrumbText: PropTypes.string,
  breadcrumbUrl: PropTypes.string,
  guideId: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  templateTypes: PropTypes.array.isRequired,
};

CreateTemplateContainer.defaultProps = {
  breadcrumbText: 'Mail Center',
  breadcrumbUrl: '/mail',
};

export default CreateTemplateContainer;
