// React components
import BioQuestionsContainer from 'components/BioQuestions/BioQuestionsContainer.js';
import BulkRaceImportContainer from 'components/Races/BulkRaceImportContainer';
import {
  ArchivedCandidateDashboardContainer,
  CandidateDashboardContainer,
  CandidateDetailsProvider,
  CandidateEventLogContainer,
} from 'components/Candidates';
import { CategoryManagementContainer } from 'components/Categories';
import CreateMailTemplate from 'components/MailCenter/CreateTemplateContainer';
import DistrictDashboardContainer from 'components/Districts/DistrictDashboardContainer.js';
import EditMailTemplate from 'components/MailCenter/EditTemplateContainer';
import GISDistrictCreateContainer from 'components/Districts/GISDistrictCreateContainer.js';
import GISDistrictDetailContainer from 'components/Districts/GISDistrictDetailContainer.js';
import GISDistrictImportContainer from 'components/Districts/GISDistrictImportContainer.js';
import ZipCodeDistrictFormContainer from 'components/Districts/ZipCodeDistrictFormContainer';
import GlobalNavigation from 'components/GlobalNavigation.js';
import GuideDashboardContainer from 'components/Guide/GuideDashboardContainer.js';
import ImportModalContainer from 'components/ImportModalContainer.js';
import LanguageSelector from 'components/LanguageSelector.js';
import MailDashboard from 'components/MailCenter/DashboardManagementContainer';
import PartyContainer from 'components/Parties/PartyContainer.js';
import QuestionSetDetailContainer from 'components/RaceQuestions/QuestionSetDetailContainer.js';
import RaceDashboardContainer from 'components/Races/RaceDashboardContainer.js';
import RaceDetailContainer from 'components/Races/RaceDetailContainer.js';
import RaceQuestionManagement from 'components/RaceQuestions/RaceQuestionManagement.js';
import SendEmailContainer from 'components/MailCenter/SendEmailContainer';
import SendLetterContainer from 'components/MailCenter/SendLetterContainer';
import { StepManagement } from 'components/RaceSteps';
import UserRoleContainer from 'components/UsersAndRoles/UserRoleContainer';
import CandidateSPA from 'scripts/candidate/CandidateSPA';
import CompositeDistrictContainer from 'components/Districts/CompositeDistrictContainer.js';
import DistrictSetContainer from 'components/Districts/DistrictSetContainer.js';
import RecyclingBinContainer from 'components/RecyclingBinContainer';
import GuideListContainer from 'components/SuperAdminSettings/GuideListContainer';
import GuideDetailContainer from 'components/SuperAdminSettings/GuideDetailContainer';
import GlobalStateInitializer from 'components/GlobalStateInitializer';
import {
  CampaignFinanceDetailContainer,
  CampaignFinanceImportContainer,
  CampaignFinanceLandingContainer,
  CandidateFinanceContainer,
} from '@/features/campaign-finance';
import ApplicationContextProvider from 'components/ApplicationContextProvider';

// We register container components for pages here so they
// can easily be loaded to the page from the Scala templates
window.ArchivedCandidateDashboardContainer = ArchivedCandidateDashboardContainer;
window.BioQuestionsContainer = BioQuestionsContainer;
window.BulkRaceImportContainer = BulkRaceImportContainer;
window.CandidateDashboardContainer = CandidateDashboardContainer;
window.CandidateDetailsProvider = CandidateDetailsProvider;
window.CandidateEventLogContainer = CandidateEventLogContainer;
window.CategoryManagementContainer = CategoryManagementContainer;
window.CandidateFinanceContainer = CandidateFinanceContainer;
window.CreateMailTemplate = CreateMailTemplate;
window.DistrictDashboardContainer = DistrictDashboardContainer;
window.EditMailTemplate = EditMailTemplate;
window.GISDistrictCreateContainer = GISDistrictCreateContainer;
window.GISDistrictDetailContainer = GISDistrictDetailContainer;
window.GISDistrictImportContainer = GISDistrictImportContainer;
window.GlobalNavigation = GlobalNavigation;
window.GuideDashboardContainer = GuideDashboardContainer;
window.ImportModalContainer = ImportModalContainer;
window.LanguageSelector = LanguageSelector;
window.MailDashboard = MailDashboard;
window.PartyContainer = PartyContainer;
window.QuestionSetDetailContainer = QuestionSetDetailContainer;
window.RaceDashboardContainer = RaceDashboardContainer;
window.RaceDetailContainer = RaceDetailContainer;
window.RaceQuestionManagement = RaceQuestionManagement;
window.SendEmailContainer = SendEmailContainer;
window.SendLetterContainer = SendLetterContainer;
window.StepManagement = StepManagement;
window.UserRoleContainer = UserRoleContainer;
window.CandidateSPA = CandidateSPA;
window.CompositeDistrictContainer = CompositeDistrictContainer;
window.ZipCodeDistrictFormContainer = ZipCodeDistrictFormContainer;
window.DistrictSetContainer = DistrictSetContainer;
window.RecyclingBinContainer = RecyclingBinContainer;
window.GuideListContainer = GuideListContainer;
window.GuideDetailContainer = GuideDetailContainer;
window.GlobalStateInitializer = GlobalStateInitializer;
window.CampaignFinanceDetailContainer = CampaignFinanceDetailContainer;
window.CampaignFinanceImportContainer = CampaignFinanceImportContainer;
window.CampaignFinanceLandingContainer = CampaignFinanceLandingContainer;
window.ApplicationContextProvider = ApplicationContextProvider;

window.React = require('react');
window.ReactDOM = require('react-dom');
