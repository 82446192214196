import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  subheading: {
    marginBottom: '0',
  },
  break: {
    wordBreak: 'break-all',
  },
  breadcrumb: {
    fontSize: '.8rem',
    color: '#666',
    marginBottom: '.4rem',
    '&:hover': {
      color: theme.palette.primary.main,
      '& a': {
        color: theme.palette.primary.main,
      },
    },
    '& a': {
      color: '#666',
      textDecoration: 'none',
    },
  },
}));

export default function PageHeader(props) {
  const {
    breadcrumbText,
    breadcrumbUrl,
    children,
    heading,
    subheading,
  } = props;
  const classes = useStyles();

  const renderBreadcrumbs = () => {
    if (breadcrumbText && breadcrumbUrl) {
      return (
        <div className={classes.breadcrumb}>
          &lt; <a href={breadcrumbUrl}>{breadcrumbText}</a>
        </div>
      );
    }
  };

  return (
    <div className="ga-banner">
      <div className="ga-container">
        <div className="mdc-layout-grid">
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
              <div className={`${classes.container} mdc-layout-grid__inner`}>
                <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-7">
                  {renderBreadcrumbs()}

                  <Typography component="h1" variant="h1" className={classes.break}>
                    {heading}
                  </Typography>

                  <Typography component="p" variant="body1" color="textSecondary" className={classes.subheading}>
                    {subheading}
                  </Typography>
                </div>

                <div className={`${classes.actionContainer} mdc-layout-grid__cell mdc-layout-grid__cell--span-5`}>
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PageHeader.propTypes = {
  breadcrumbText: PropTypes.string,
  breadcrumbUrl: PropTypes.string,
  children: PropTypes.node,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
};

PageHeader.defaultProps = {
  breadcrumbText: '',
  breadcrumbUrl: '',
  subheading: '',
};
